<template>
  <v-overlay :value="show">
    <v-card
      v-click-outside="close"
      width="1300px"
      light
      class="pb-5"
      color="#333333"
    >
      <div class="d-flex justify-space-between py-3 px-5">
        <h3>
          Уведомление ЛК
        </h3>
        <v-btn
          color="error"
          small
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div>
        <v-col
          class="white"
          cols="12"
        >
          <v-textarea
            v-model="message"
            rows="2"
            placeholder="Введите ваше сообщение"
          />
        </v-col>
        <div class="d-flex justify-end">
          <v-btn
            :disabled="!orderId"
            dark
            color="#5CB7B1"
            class="mx-5 mt-5"
            @click="submit"
          >
            Отправить
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-overlay>
</template>
<script>
import modalWindowMixin from "@/mixins/modal-window.mixin";
import {mapGetters} from 'vuex';
import axios from "axios";
import {MSG} from "@/http-routes";

export default {
  mixins: [modalWindowMixin],
  props: {
    id: {
      type: Number,
      required: true,
    },
    rootUserId: {
      type: Number,
      required: true,
    },
    module: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    message: '',
    document: null,
    orderId:null
  }),
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      items: "journal/getItems"
    })
  },
  watch: {
    show: {
      handler(nv) {
        if (nv === false){

          this.clear()
        }else {
          this.getOrderId()
        }
      },
      immediate: true
    }
  },
  methods: {
    getOrderId(){
      this.$store.dispatch('rs/getOrderId', this.id).then(res => {
        this.orderId = res.data
      })
    },
    clear() {
      this.message = ''
    },
    submit() {
      if(this.message) {
        axios.post(`${MSG}/newdeclarant/application/message`, {
          message:this.message,
          id:this.orderId,
        }).then(() => {
          this.$snackbar({
            top:false,
            color:'green',
            text:'Отправлено'
          })
          this.clear()
          this.close()
        }).catch(() => {
          this.$snackbar({
            top:false,
            color:'red',
            text:'Ошибка'
          })
        })
      }

    }
  }


}
</script>
<style scoped>
.messages {
  height: 300px;
  overflow-y: scroll;
}

.message {
  width: max-content;
}

.message__client {
  align-self: end;
}
</style>
